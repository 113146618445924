<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">

                <div class="card elevation-5">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <div class="col-lg-2 col-6">
                                <v-select v-model="millModel" :items="millLists" default="" item-value="mill_id" item-text="mill_name" label="Production From(Mill)" clearable dense prepend-icon="mdi-factory"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="regionModel" :items="regionLists" default="" item-value="region" item-text="region" label="Region" :disabled="disabled_region == true" @change="(event) => regionOnChange(event)" clearable dense prepend-icon="mdi-apps"></v-select>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-select v-model="officeModel" :items="officeLists" default="" item-value="office_id" item-text="office" :label=labelBranch :loading="officeLoading" :disabled="disabled_office == true" @change="(event) => officeOnChange(event)" clearable dense prepend-icon="mdi-home-variant"></v-select>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-select v-model="salesmanModel" :items="salesmanLists" default="" item-value="SalesId" item-text="NamaSales" :label=labelSales :loading="salesmanLoading" :disabled="disabled_salesman == true" @change="(event) => salesmanOnchange(event)" clearable dense prepend-icon="mdi-account"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="projTypeModel" :items="projTypeLists" default="" item-value="pr_jenis" item-text="pr_jenis" label="Type" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-4 col-6">
                                <v-select v-model="projCategoryModel" :items="projCategoryLists" default="" item-value="str1" item-text="str2" label="Category" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="projStageModel" :items="projStageLists" default="" item-value="str1" item-text="str2" label="Stage" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-dialog ref="dialog" v-model="dateStartModal" :return-value.sync="dateStartModel" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateStartModel" label="Start Date" dense readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateStartModel" :show-current="true" @click:date="$refs.dialog.save(dateStartModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateStartModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-dialog ref="dialog2" v-model="dateEndModal" :return-value.sync="dateEndModel" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateEndModel" label="End Date" dense readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateEndModel" :show-current="true" @click:date="$refs.dialog2.save(dateEndModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateEndModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-btn class="mt-3" color="primary" block elevation="2" small rounded @click="getData()" dark>SEARCH<v-icon right>mdi-magnify</v-icon></v-btn>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-lg-12">

                <div class="card elevation-5" v-show="resultCard">
                    <div class="card-body">
                        <v-data-table :headers="headers" :items="itemLists" class="elevation-1" :search="searchItem" :loading="loadingDatatable" dense>
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <div class="col-6 col-lg-6 text-left">
                                        <v-text-field v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                    </div>
                                    <div class="col-6 col-lg-6 text-right">
                                        <v-btn class="btn btn-primary" rounded text @click="exportToExcel()" dense dark>EXPORT EXCEL</v-btn>
                                    </div>
                                </v-toolbar>
                            </template>
                        </v-data-table>
                    </div>
                </div>

            </div>

        </div>

    </div>
    
</template>

<script>

import exportFromJSON from "export-from-json"

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'E-Kencana',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'Project Management',
                disabled: false,
                href: '#',
                },
                {
                text: 'Project Report',
                disabled: true,
                href: '#',
                },
            ],
            millModel: '',
            millLists: [],
            regionModel: '',
            regionLists: [],
            disabled_region: false,
            officeModel: '',
            officeLists: [],
            labelBranch: 'Branch Office',
            officeLoading: false,
            disabled_office: false,
            salesmanModel: '',
            salesmanLists: [],
            labelSales: 'Salesman',
            salesmanLoading: false,
            disabled_salesman: false,
            projTypeModel: '',
            projTypeLists: [
                {
                    pr_jenis: 'SWASTA NASIONAL',
                },
                {
                    pr_jenis: 'BUMN',
                },
                // {
                //     pr_jenis: 'PMA',
                // }
            ],
            projCategoryModel: '',
            projCategoryLists: [],
            projStageModel: '',
            projStageLists: [],
            dateStartModal: false,
            dateStartModel: '',
            dateStartFormatted: '',
            dateEndModal: false,
            dateEndModel: '',
            dateEndFormatted: '',
            headers: [
                { text: 'DATE', value: 'DATE_CREATED', align: 'left', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'OFFICE', value: 'OFFICE_TEXT', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'SALESMAN', value: 'NAMA_SALES', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DESC.', value: 'PROJECT_NAME', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'TYPE', value: 'PROJECT_TYPE', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'CATEGORY', value: 'PROJECT_CATEGORY', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'SURVEY DATE', value: 'SURVEY_DATE', align: 'left', class: 'primary--text blue lighten-5', sortable: false },
                { text: 'LOCATION', value: 'PROJECT_LOCATION', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'PROVINCE', value: 'PROVINCE', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'PROJECT STAGE', value: 'PROJECT_STAGE', align: 'left', class: 'primary--text blue lighten-5' },
                { text: '', value: 'details', align: 'center', class: 'primary--text blue lighten-5', sortable: false },
            ],
            itemLists: [],
            searchItem: '',
            loadingDatatable: false,
            resultCard: false
        }
    },
    async mounted(){
        
        this.initialize()

    },
    methods:{
        
        async initialize(){
            
            this.$store.dispatch('setOverlay', true)

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/ProjectReport`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                console.log(res.data)

                this.millLists = res.data.mill
                if(res.data.region.length == 1){
                    this.regionModel = res.data.region[0].region
                    this.regionLists = res.data.region
                    this.disabled_region = true
                } else {
                    this.regionLists = res.data.region
                    this.disabled_region = false
                }
                if(res.data.office.length == 1){
                    this.officeModel = res.data.office[0].office_id
                    this.officeLists = res.data.office
                    this.disabled_office = true
                } else {
                    this.officeLists = res.data.office
                    this.disabled_office = false
                }
                if(res.data.sales.length == 1){
                    this.salesmanModel = res.data.sales[0].SalesId
                    this.salesmanLists = res.data.sales
                    this.disabled_salesman = true
                } else {
                    this.salesmanLists = res.data.sales
                    this.disabled_salesman = false
                }
                this.projCategoryLists = res.data.category
                this.projStageLists = res.data.stage

                this.$store.dispatch('setOverlay', false)

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async getData(){

            this.$store.dispatch('setOverlay', true)

            this.loadingDatatable = true

            this.itemLists = []

            if (this.dateStartModel) {
                this.dateStartFormatted = new Date(this.dateStartModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateStartFormatted = ''
            }

            if (this.dateEndModel) {
                this.dateEndFormatted = new Date(this.dateEndModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateEndFormatted = ''
            }

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/ProjectReport/getData`, { 

                mill: this.millModel ? this.millModel : '',
                region: this.regionModel ? this.regionModel : '',
                office: this.officeModel ? this.officeModel : '',
                sales: this.salesmanModel ? this.salesmanModel : '',
                projType: this.projTypeModel ? this.projTypeModel : '',
                projCategory: this.projCategoryModel ? this.projCategoryModel : '',
                projStage: this.projStageModel ? this.projStageModel : '',
                dateStart: this.dateStartFormatted,
                dateEnd: this.dateEndFormatted,

                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                this.$store.dispatch('setOverlay', false)

                this.resultCard = true

                this.itemLists = res.data.result
                this.loadingDatatable = false
                
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                console.log(err)
                // if(err.response.status == '401'){
                //     this.$router.push('/');
                // }

            })

        },

        async regionOnChange(id){

            this.officeLoading = true
            this.salesmanLoading = true

            if(id){

                await axios.get(`${process.env.VUE_APP_URL}/api/kmb/ProjectReport/regionOnChange?region=${id}`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    this.officeLoading = false
                    this.officeLists = res.data.office
                    this.labelBranch = 'Branch Office(' + res.data.countOffice + ')'
                    this.salesmanLoading = false
                    this.salesLists = res.data.sales
                    this.labelSales = 'Salesman(' + res.data.countSales + ')'

                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })

            } else {

                await axios.get(`${process.env.VUE_APP_URL}/api/kmb/ProjectReport/regionOnChange?id=all`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    this.officeLoading = false
                    this.officeLists = res.data.office
                    this.labelBranch = 'Branch Office(' + res.data.countOffice + ')'
                    this.salesmanLoading = false
                    this.salesLists = res.data.sales
                    this.labelSales = 'Salesman(' + res.data.countSales + ')'

                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })

            }

        },

        async officeOnChange(id){

            this.salesmanLoading = true

            if(id){
                axios.get(`${process.env.VUE_APP_URL}/api/kmb/ProjectReport/officeOnChange?&region=${this.regionModel ? this.regionModel : ''}&office=${id ? id : ''}`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    this.salesmanLoading = false
                    this.salesmanLists = res.data.sales
                    this.labelSales = 'Salesman(' + res.data.countSales + ')'

                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })

            } else {
                axios.get(`${process.env.VUE_APP_URL}/api/kmb/ProjectReport/officeOnChange?id=all`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    this.salesmanLoading = false
                    this.salesmanLists = res.data.sales
                    this.labelSales = 'Salesman(' + res.data.countSales + ')'

                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })
            }

        },

        exportToExcel(){

            const data = this.itemLists
            const fileName = "Project-Report-Excel-Data"
            const exportType = exportFromJSON.types.csv

            if (data) exportFromJSON({ data, fileName, exportType })

            Swal.fire({
                title: 'Success',
                text: 'Data exported successfully, check your download folder.'
            })

        }

    },
    watch: {

        
    }
    
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

    .list-heading {
        font-weight: normal;
    }

</style>